// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBsTEe8TR7Giyyljyc2hEaYRPkpr99oI1Q",
    authDomain: "shop-d7c5d.firebaseapp.com",
    projectId: "shop-d7c5d",
    storageBucket: "shop-d7c5d.appspot.com",
    messagingSenderId: "224678653986",
    appId: "1:224678653986:web:7ba29ea06590f98fd29a7b"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export {auth,provider};
